import get from "lodash/get";
import {
  asosGlobalNamespace,
  manifestWindowKey,
  featureManifestWindowKey
} from "@template/constants";
import { noticeError, addPageAction } from "@src/helpers/monitoring";
import clientConfig from "@src/template/clientConfig";
import { eventHandlers } from "@src/client/analytics/adobe";
import { getCoreAttributes } from "./getCoreAttributes";

export { startFeatures } from "./startFeatures";

export const getFeaturesFromWindowManifest = () => {
  if (get(window, `[${asosGlobalNamespace}][${manifestWindowKey}]`)) {
    const manifest = window[asosGlobalNamespace][manifestWindowKey];

    const features = {
      features: manifest.features || {},
      mvt: manifest.mvt || {}
    };

    return features;
  }
  return {};
};

const getFeaturesOptions = context => {
  const { OPTIMIZELY_TEMPLATE_URL, OPTIMIZELY_SDK_KEY } = clientConfig.get();

  const initialConfig = {
    sdkKey: OPTIMIZELY_SDK_KEY,
    datafileOptions: {
      urlTemplate: OPTIMIZELY_TEMPLATE_URL
    },
    eventBatchSize: 10,
    eventFlushInterval: 1000,
    logger: {
      log: (level, message) => {
        if (level > 2) {
          addPageAction("Optimizely Monitor", {
            message,
            logLevel: level
          });
        }
      }
    },
    errorHandler: {
      handleError: error => {
        noticeError(error);
      }
    }
  };

  const coreAttributes = getCoreAttributes(context);

  const activationCallBack = (data, forceTracking) => {
    eventHandlers["tracking.experiments"]({
      experimentKey: data.experiment.key,
      experimentVariation: data.variation.key,
      forceTracking: forceTracking
    });
  };

  return [initialConfig, coreAttributes, activationCallBack];
};

export const getFeatureManifest = () =>
  window[asosGlobalNamespace][featureManifestWindowKey] ||
  /* backward compatibility */ window.__featureManifest;

const getFeaturesLegacy = context =>
  new Promise(resolve => {
    requirejs(["asos-web-features"], ({ default: features }) => {
      const instance = features.getInstance(getFeatureManifest(), context);
      resolve(instance);
    });
  });

const getFeatures = context =>
  new Promise(resolve => {
    requirejs(["@asos-web-features/browser"], ({ getInstance }) => {
      const instance = getInstance(...getFeaturesOptions(context));
      resolve(instance);
    });
  });

export const initialise = context =>
  Promise.all([getFeaturesLegacy(context), getFeatures(context)]).then(
    instances =>
      instances.reduce((acc, value) => {
        return { ...acc, ...value };
      }, {})
  );

export const addManifestFeatures = instance => {
  instance.addManifest({ ...getFeaturesFromWindowManifest() });
  return instance;
};
