import { Component } from "react";
import PropTypes from "prop-types";
import loadSecureScriptContainer from "./loadSecureScriptContainer";

class SecureScriptContainer extends Component {
  static propTypes = {
    isContainerEnabled: PropTypes.bool,
    webContext: PropTypes.object
  };

  componentDidUpdate() {
    loadSecureScriptContainer();
  }

  render() {
    return null;
  }
}

export default SecureScriptContainer;
