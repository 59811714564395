import PropTypes from "prop-types";
import styles from "./index.css";

const ResultList = ({ children }) => (
  <ul
    id="search-results"
    className={styles.list}
    aria-labelledby="chrome-search-label"
    role="listbox"
  >
    {children}
  </ul>
);

ResultList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ResultList;
