import { connect } from "react-redux";
import DeliveryProposition from "./DeliveryProposition";
import {
  getDefaultStoreUrl,
  getDefaultLanguage
} from "../../../../state/modules/regionalStore";

const mapStateToProps = state => ({
  defaultStoreUrl: getDefaultStoreUrl(state),
  lang: getDefaultLanguage(state)
});

export default connect(mapStateToProps)(DeliveryProposition);
