import logger from "../helpers/logger";
import { storePresentation } from "@asosteam/asos-web-store-presentation-node";
import env from "../env";

const oneMinute = 1000 * 60;
let services;

export const setupStorePresentation = () =>
  (services = storePresentation({
    storeApiEndpoint: env("ASOS_STORE_API_ENDPOINT"),
    storeExpiryMs: oneMinute,
    versionManifestEndpoint: env("VERSION_MANIFEST_ENDPOINT"),
    versionManifestExpiryMs: oneMinute,
    warn: logger.warn,
    environmentMode: env("CLIENT_ENVIRONMENT_MODE")
  }));

export const getStore = () => services.store;
