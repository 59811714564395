import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { keyCodes } from "../../../globalEventHandler";
import styles from "./index.css";

const cx = classNames.bind(styles);

class ResultItem extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      searchTerm: PropTypes.string.isRequired,
      numberOfResults: PropTypes.number
    }),
    onSelect: PropTypes.func.isRequired,
    term: PropTypes.string.isRequired
  };

  static bold = (match, word) => {
    const boldIndex = word.toLowerCase().indexOf(match.toLowerCase());

    return (
      <>
        {word.substring(0, boldIndex)}
        <span className={styles.bold}>
          {word.substr(boldIndex, match.length)}
        </span>
        {word.substring(boldIndex + match.length)}
      </>
    );
  };

  static boldTermInText = (suggestion, term) => {
    const searchWords = term.split(/ /g);
    const suggestionWords = suggestion.split(/ /g);

    return suggestionWords.map((suggestionWord, index) => {
      const firstMatch = searchWords.find(searchTerm =>
        suggestionWord.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const word = firstMatch
        ? ResultItem.bold(firstMatch, suggestionWord)
        : suggestionWord;

      return (
        <Fragment key={index}>
          {word}
          {index < suggestionWords.length - 1 ? " " : ""}
        </Fragment>
      );
    });
  };

  static formatText = phrase => {
    return phrase
      .replace(/asos/i, "ASOS")
      .split(/ /g)
      .map(word => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
      .join(" ");
  };

  handleSubmit = () => {
    const {
      onSelect,
      item: { searchTerm }
    } = this.props;

    onSelect(searchTerm);
  };

  handleKeyDown = e => {
    if ([keyCodes.enter, keyCodes.space].includes(e.keyCode)) {
      const {
        onSelect,
        item: { searchTerm }
      } = this.props;

      onSelect(searchTerm);
    }
  };

  renderResultLabel(label) {
    const { term } = this.props;

    const text = ResultItem.formatText(label);
    const jsx = ResultItem.boldTermInText(text, term);

    return { text, jsx };
  }

  renderResultCount(resultCount) {
    if (!resultCount) {
      return null;
    }

    const text = resultCount.toLocaleString().replace(/\.00$/, "");
    const jsx = <span className={styles.count}>{text}</span>;

    return { text, jsx };
  }

  render() {
    const {
      active,
      id,
      item: { numberOfResults, searchTerm }
    } = this.props;

    const resultLabel = this.renderResultLabel(searchTerm);
    const resultCount = this.renderResultCount(numberOfResults);
    const ariaLabel =
      resultLabel.text + (resultCount ? ` ${resultCount.text}` : "");

    return (
      <li
        id={id}
        className={cx(styles.option, {
          [styles.option__active]: active
        })}
        role="option"
        aria-disabled="false"
        aria-label={ariaLabel}
        aria-selected={active}
        onClick={this.handleSubmit}
        onKeyDown={this.handleKeyDown}
      >
        {resultLabel.jsx}
        {resultCount ? resultCount.jsx : null}
      </li>
    );
  }
}

export default ResultItem;
