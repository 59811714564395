import { getKeyStoreDataversion as getKeyStoreDataVersionFromCookie } from "@asosteam/asos-web-store-presentation-node";
import { getContext } from "@src/client/context";
import clientConfig from "@template/clientConfig";

import contentApiParameters from "./contentApi/contentApiParameters";
import { stringify } from "query-string";

/* These are going to be env vars */
import {
  SEARCH_API_SUGGESTIONS_PATH,
  COUNTRY_API_SELECTOR_PATH,
  COUNTRY_API_WELCOME_MESSAGE_PATH,
  PAYMENT_PROVIDER_API_PATH,
  X_SITE_ORIGIN,
  CUSTOMER_PROFILE_API_PATH,
  DELIVERY_OPTIONS_API_PATH
} from "./constants";

const clientVariables = clientConfig.get();

const xSiteOriginCheck = params => {
  if (
    (clientConfig.isLocal() || clientConfig.isServer()) &&
    clientVariables.API_XSITEORIGIN_BASE
  ) {
    return { ...params, [X_SITE_ORIGIN]: clientVariables.API_XSITEORIGIN_BASE };
  }

  if (!clientConfig.isServer() && clientConfig.getXSiteOrigin()) {
    return { ...params, [X_SITE_ORIGIN]: clientConfig.getXSiteOrigin() };
  }

  return params;
};

const checkEmptyParams = params => {
  const ret = {};
  Object.keys(params).forEach(key => {
    if (
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ""
    ) {
      ret[key] = params[key];
    }
  });
  return ret;
};

const getKSDV = () => {
  const context = getContext();
  if (!context) {
    return getKeyStoreDataVersionFromCookie();
  }
  return context.getKeyStoreDataversion();
};

const ensureKeyStoreDataversion = params =>
  params.keyStoreDataversion
    ? params
    : { ...params, keyStoreDataversion: getKSDV() };

const getApiRoot = () =>
  clientConfig.isServer()
    ? clientVariables.API_BASE
    : clientVariables.RELATIVE_API_BASE;

export const buildUrl = (origin, params = {}) => {
  params = ensureKeyStoreDataversion(params);
  params = xSiteOriginCheck(params);
  params = checkEmptyParams(params);
  const queryString = stringify(params);
  const url = origin + (queryString ? `?${queryString}` : "");
  return url;
};

export const getSuggestionsUrl = (storeCode, lang, countryCode, query) => {
  const params = {
    store: storeCode,
    lang,
    q: query,
    country: countryCode,
    limit: 10
  };
  const baseUrl = `${clientVariables.RELATIVE_API_BASE}${SEARCH_API_SUGGESTIONS_PATH}`;
  return buildUrl(baseUrl, params);
};

export const getNavigationUrl = (params, preview = false) => {
  const { NAVIGATION_API_PATH } = clientVariables;
  const API_BASE = preview
    ? "https://www.asos.com/api"
    : clientVariables.API_BASE;
  const baseUrl = `${API_BASE}${NAVIGATION_API_PATH}`;
  return buildUrl(baseUrl, params);
};

export const getNavigationSubtreeUrl = params => {
  // This only gets used client-side, so we can always use the relative API to avoid CORS errors
  const { NAVIGATION_SUBTREE_API_PATH, RELATIVE_API_BASE } = clientVariables;
  const baseUrl = `${RELATIVE_API_BASE}${NAVIGATION_SUBTREE_API_PATH}`;
  return buildUrl(baseUrl, params);
};

export const getCountryUrl = (country, lang, platform) => {
  const params = { lang, platform };
  const API_BASE = getApiRoot();
  const baseUrl = `${API_BASE}${COUNTRY_API_SELECTOR_PATH}/${country}`;
  return buildUrl(baseUrl, params);
};

export const getPaymentProviderUrl = params => {
  const API_BASE = getApiRoot();
  const baseUrl = `${API_BASE}${PAYMENT_PROVIDER_API_PATH}`;
  return buildUrl(baseUrl, params);
};

export const getWelcomeMessageUrl = (
  geoCountry,
  browseCountry,
  platform,
  deliveryCountry
) => {
  const params = {
    browseCountry,
    platform,
    ...(deliveryCountry ? { deliveryCountry } : { geoCountry })
  };

  const API_BASE = getApiRoot();
  const baseUrl = `${API_BASE}${COUNTRY_API_WELCOME_MESSAGE_PATH}`;

  return buildUrl(baseUrl, params);
};

export const getContentApiUrl = (parameters, context) => {
  const queryString = contentApiParameters.generate(parameters, context);
  if (clientVariables.CONTENT_API_PATH) {
    return `${clientVariables.CONTENT_API_PATH}${queryString}`;
  }
  return null;
};

export const getCustomerProfileUrl = ({
  customerId,
  endpoint = "",
  params = {}
}) => {
  const API_BASE = getApiRoot();
  const baseUrl = `${API_BASE}${CUSTOMER_PROFILE_API_PATH}/${customerId}${endpoint}`;

  return buildUrl(baseUrl, params);
};

export const getDeliveryOptionsUrl = (defaultDeliveryCountry, params = {}) => {
  const API_BASE = getApiRoot();
  const baseUrl = `${API_BASE}${DELIVERY_OPTIONS_API_PATH}/${defaultDeliveryCountry}`;

  return buildUrl(baseUrl, params);
};
