export const optimizelyActivator = () => {
  const OPTIMIZELY_SCRIPT_ID = "19181552552";
  const path = window.location.pathname;
  const optimizelyEnabled =
    !/\/(bag\/?$|saved-lists\/$|search\/?|cat\/|gift-vouchers\/?$|see-similar\/|shared-board\/)/i.test(
      path
    );

  if (optimizelyEnabled) {
    const script = document.createElement("script");
    script.src = `https://cdn.optimizely.com/js/${OPTIMIZELY_SCRIPT_ID}.js`;
    script.defer = true;

    document.body.appendChild(script);
  }
};
