import { connect } from "react-redux";
import {
  getFeature,
  getFeaturesLoaded
} from "@template/state/modules/features";
import SecureScriptContainer from "./SecureScriptContainer";

const mapStateToProps = state => {
  const { isContainerEnabled = false } =
    (getFeaturesLoaded(state) && getFeature(state, "ssc-browse")) || {};
  return {
    isContainerEnabled
  };
};

export default connect(mapStateToProps, null)(SecureScriptContainer);
