class ContentApiParameters {
  generate(parameters, context) {
    const encodedContext = this.objectToBase64(context);
    const queryParameters = {
      ...parameters,
      context: encodedContext
    };
    const filteredQueryParameters = this.filterEmptyParameters(queryParameters);

    return this.buildQueryString(filteredQueryParameters);
  }

  filterEmptyParameters(parameters) {
    const queryParameters = {};

    Object.keys(parameters).forEach(key => {
      if (parameters[key]) {
        queryParameters[key] = parameters[key];
      }
    });

    return queryParameters;
  }

  objectToBase64(contextParameters) {
    if (!contextParameters || Object.keys(contextParameters).length === 0) {
      return null;
    }

    const stringifiedParams = JSON.stringify(contextParameters);
    const encodedParams = btoa(stringifiedParams);
    return encodedParams
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  buildQueryString(queryParameters) {
    if (Object.keys(queryParameters).length === 0) {
      return "";
    }

    let queryString = "?";

    for (const parameter in queryParameters) {
      if (queryString === "?") {
        queryString = `${queryString}${parameter}=${queryParameters[parameter]}`;
      } else {
        queryString = `${queryString}&${parameter}=${queryParameters[parameter]}`;
      }
    }

    return queryString;
  }
}
export default new ContentApiParameters();
