import { connect } from "react-redux";

import { isLoggedIn } from "../../state/modules/user";
import {
  getCountryCode,
  getDefaultLanguage,
  getDefaultStoreUrl,
  getKeyStoreDataversion,
  getStoreCode
} from "../../state/modules/regionalStore";
import { getFeature } from "../../state/modules/features";

import MyAccountLinks from "./MyAccountLinks";
import { withCurrentEvent } from "@template/helpers/checkDateForEvent";

function mapStateToProps(state) {
  return {
    countryCode: getCountryCode(state),
    defaultStoreUrl: getDefaultStoreUrl(state),
    isLoggedIn: isLoggedIn(state),
    keyStoreDataversion: getKeyStoreDataversion(state),
    lang: getDefaultLanguage(state),
    myReturnsToggle: getFeature(state, "acc-returnsEnabledCountries"),
    storeCode: getStoreCode(state)
  };
}

export default connect(mapStateToProps)(withCurrentEvent(MyAccountLinks));
