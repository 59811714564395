import {
  FETCHING_TARGETED_CONTENT,
  FETCHED_TARGETED_CONTENT,
  GET_STORED_TARGETED_CONTENT,
  API_ERROR
} from "./constants";
import { emitContentTargetingEvent, emitNewRelicEvent } from "./events";
import Storage from "./storage";

export const requestTargetedContent = (parameters, context) => {
  return (dispatch, getState, { api }) => {
    const { fetchTargetedContent } = api;
    const parametersAndContext = { ...parameters, ...context };

    const storage = new Storage();
    const storedData = storage.getData(parametersAndContext);

    if (storedData) {
      emitContentTargetingEvent(storedData);
      dispatch({ type: GET_STORED_TARGETED_CONTENT, payload: storedData });
    } else {
      dispatch({ type: FETCHING_TARGETED_CONTENT });
      fetchTargetedContent(parameters, context)
        .then(res => {
          storage.setData(res, parametersAndContext);
          emitContentTargetingEvent(res);
          dispatch({ type: FETCHED_TARGETED_CONTENT, payload: res });
        })
        .catch(err => {
          emitContentTargetingEvent(null);
          emitNewRelicEvent(err);
          dispatch({ type: API_ERROR });
        });
    }
  };
};
