import buildListItemMainContent from "./buildListItemMainContent";
import getListElement from "./getListElement";
import { decodeBreadcrumbs } from "./decodeBreadcrumbs";

const buildBreadcrumbs = breadcrumbs => {
  if (!breadcrumbs) {
    return null;
  }

  const listElement = getListElement();

  listElement.innerHTML = "";

  decodeBreadcrumbs(breadcrumbs).forEach(({ Url, Label }, index) => {
    const listItem = document.createElement("li");

    if (index) {
      const span = document.createElement("span");
      span.setAttribute("aria-hidden", true);
      span.textContent = "›";
      listItem.appendChild(span);

      const spacer = document.createTextNode(" ");
      listItem.appendChild(spacer);
    }

    listItem.appendChild(buildListItemMainContent({ Url, Label }));

    listElement.appendChild(listItem);
  });

  return listElement;
};

export default buildBreadcrumbs;
