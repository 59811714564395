import { Fragment } from "react";
import PropTypes from "prop-types";
import LegalBar from "../LegalBar";
import styles from "./Footer.css";
import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../state/modules/layout";
import MainContent from "./MainContent";

const Footer = ({ isAccordion, ...rest }) => (
  <footer className={styles.container} data-testid="footer">
    {isAccordion ? (
      <MainContent isAccordion={true} {...rest} viewable={[SMALL_VIEWPORT]} />
    ) : (
      <Fragment>
        <MainContent
          isAccordion={false}
          {...rest}
          viewable={[MEDIUM_VIEWPORT, LARGE_VIEWPORT]}
        />
        <LegalBar />
      </Fragment>
    )}
  </footer>
);

Footer.propTypes = {
  isAccordion: PropTypes.bool
};

export default Footer;
