import { Component } from "react";
import PropTypes from "prop-types";
import GlobalBanner from "@asosteam/asos-web-global-banners";

class WithTargetedContent extends Component {
  constructor() {
    super();
    this.state = {
      contentRequested: false,
      subscriptionsSdkRequested: false
    };
  }

  componentDidMount() {
    this.manageRequest();
  }

  componentDidUpdate() {
    this.manageRequest();
  }

  manageRequest() {
    if (
      this.props.identityHasResponded &&
      !this.state.subscriptionsSdkRequested
    ) {
      this.props.getSubscriptionsFromSDK();
      this.setState({ subscriptionsSdkRequested: true });
    }

    if (
      this.props.identityHasResponded &&
      this.props.subscriptionsHasResponded &&
      !this.state.contentRequested
    ) {
      const parameters = this.getParameters({ ...this.props });
      const context = this.getContext({ ...this.props });

      this.props.requestTargetedContent(parameters, context);
      this.setState({ contentRequested: true });
    }
  }

  getParameters(props) {
    const viewscope = this.getViewScope();
    const params = {
      country: props.country,
      store: props.store,
      lang: props.lang,
      keystoredataversion: props.keystoredataversion,
      ...(viewscope !== "default" && { viewscope: viewscope })
    };

    return params;
  }

  getContext(props) {
    return {
      premier: props.hasSubscriptions ? "T" : "F",
      customerStatus:
        props.isRecognised || props.isLoggedIn ? "known" : "unknown"
    };
  }

  getViewScope() {
    return window.asos.webContext.getWebContext().floor;
  }

  render() {
    const emptyReturnValue =
      this.props.contentTargetingStatus === "SUCCESS" && !this.props.content;

    if (this.props.contentTargetingStatus === "ERROR" || emptyReturnValue) {
      return null;
    }

    return (
      <GlobalBanner
        content={this.props.content}
        status={this.props.contentTargetingStatus}
      />
    );
  }
}

WithTargetedContent.defaultProps = {
  isRecognised: false
};

WithTargetedContent.propTypes = {
  content: PropTypes.object,
  requestTargetedContent: PropTypes.func.isRequired,
  identityHasResponded: PropTypes.bool.isRequired,
  contentTargetingStatus: PropTypes.string.isRequired,
  getSubscriptionsFromSDK: PropTypes.func.isRequired,
  hasSubscriptions: PropTypes.bool.isRequired,
  subscriptionsHasResponded: PropTypes.bool.isRequired
};

export default WithTargetedContent;
